import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "tab",
    "editableCell",
    "tableContainer",
    "removeAction",
    "editButton",
    "addPortcoModal",
    "portfolioCompanySelect",
    "addRowTr",
    "existingCompanyForm",
    "newCompanyForm",
    "newCompanyLegalName",
    "newCompanyDisplayName",
  ]
  static values = {
    editMode: Boolean,
    editingTab: String,
  }

  updateTable() {
    $.get(window.location.href, (response) => {
      const tempDiv = document.createElement("div")
      tempDiv.innerHTML = response

      // Update all tab contents
      const tabs = [
        "summary",
        "portfolio-companies",
        "financials-statements",
        "distribution-allocation",
        "money",
      ]
      tabs.forEach((tabId) => {
        const newTable = tempDiv.querySelector(
          `#${tabId} .b-manage-status-table-container`,
        )
        const currentTable = document.querySelector(
          `#${tabId} .b-manage-status-table-container`,
        )
        if (newTable && currentTable) {
          currentTable.innerHTML = newTable.innerHTML
        }
      })
    })
  }

  connect() {
    // Check URL params for active tab
    const urlParams = new URLSearchParams(window.location.search)
    const activeTab = urlParams.get("tab") || "summary"
    this.activateTab(activeTab)

    // Initialize edit button visibility
    if (!this.editModeValue) {
      this.editButtonTarget.style.display = [
        "portfolio-companies",
        "distribution-allocation",
        "financials-statements",
      ].includes(activeTab)
        ? "inline-block"
        : "none"
    }
  }

  switchTab(event) {
    const tabId = event.currentTarget.dataset.tabId
    this.activateTab(tabId)

    // Update URL without page reload
    const url = new URL(window.location)
    url.searchParams.set("tab", tabId)
    window.history.pushState({}, "", url)

    // Show/hide edit button based on tab
    if (!this.editModeValue) {
      this.editButtonTarget.style.display = [
        "portfolio-companies",
        "distribution-allocation",
        "financials-statements",
      ].includes(tabId)
        ? "inline-block"
        : "none"
    }
  }

  activateTab(tabId) {
    // Remove active class from all tabs and panes
    document.querySelectorAll(".nav-link").forEach((tab) => {
      tab.classList.remove("active")
    })
    document.querySelectorAll(".tab-pane").forEach((pane) => {
      pane.classList.remove("show", "active")
    })

    // Activate selected tab and pane
    const selectedTab = document.querySelector(`[data-tab-id="${tabId}"]`)
    const selectedPane = document.querySelector(`#${tabId}`)

    if (selectedTab && selectedPane) {
      selectedTab.classList.add("active")
      selectedPane.classList.add("show", "active")
    }
  }

  toggleEdit(e) {
    e.preventDefault()

    // Get current active tab
    const activeTab = document.querySelector(".tab-pane.active")
    const activeTabId = activeTab.id

    if (this.editModeValue) {
      // Save mode
      let payload = {
        snapshot_id: this.element.dataset.snapshotId,
        tab: this.editingTabValue,
      }

      switch (this.editingTabValue) {
        case "portfolio-companies":
          const companies = []
          const rows = document
            .querySelector("#portfolio-companies-table tbody")
            .querySelectorAll("tr:not(.totals-row):not(.d-none)")

          rows.forEach((row) => {
            const cells = row.querySelectorAll("td")
            if (cells.length >= 3) {
              companies.push({
                legal_name: cells[0].textContent.trim(),
                cost: parseFloat(
                  cells[1].querySelector("input")?.value ||
                    cells[1].textContent.trim(),
                ),
                fair_value: parseFloat(
                  cells[2].querySelector("input")?.value ||
                    cells[2].textContent.trim(),
                ),
              })
            }
          })
          payload.companies = companies
          break

        case "financials-statements":
          const financialsTable = document.querySelector(
            "#financials-statements table tbody",
          )
          payload.financials = {
            total_assets: parseFloat(
              financialsTable.querySelector(
                "tr:nth-child(1) td:nth-child(1) input",
              )?.value || "0",
            ),
            total_investments: parseFloat(
              financialsTable.querySelector(
                "tr:nth-child(2) td:nth-child(1) input",
              )?.value || "0",
            ),
            total_liabilities: parseFloat(
              financialsTable.querySelector(
                "tr:nth-child(3) td:nth-child(1) input",
              )?.value || "0",
            ),
            total_partners_capital: parseFloat(
              financialsTable.querySelector(
                "tr:nth-child(4) td:nth-child(1) input",
              )?.value || "0",
            ),
          }
          payload.statements = {
            balance: parseFloat(
              financialsTable.querySelector(
                "tr:nth-child(1) td:nth-child(2) input",
              )?.value || "0",
            ),
            calls: parseFloat(
              financialsTable.querySelector(
                "tr:nth-child(2) td:nth-child(2) input",
              )?.value || "0",
            ),
            distributions: parseFloat(
              financialsTable.querySelector(
                "tr:nth-child(3) td:nth-child(2) input",
              )?.value || "0",
            ),
            time_period:
              financialsTable
                .querySelector("tr:nth-child(4) td:nth-child(2) select")
                ?.value?.toLowerCase() || "",
          }
          break

        case "distribution-allocation":
          const distributionRows = document
            .querySelector("#distribution-allocation tbody")
            .querySelectorAll(
              "tr:not(.totals-row):not(.sub-totals-row):not(.d-none)",
            )

          const distributions = []
          let otherBalanceSheetItems = 0

          distributionRows.forEach((row) => {
            const cells = row.querySelectorAll("td")
            if (cells.length >= 2) {
              const legalName = cells[0].textContent.trim()
              const value = parseFloat(
                cells[1].querySelector("input")?.value ||
                  cells[1].textContent.trim(),
              )

              if (legalName === "Other Balance Sheet Items/Fees") {
                otherBalanceSheetItems = value
              } else {
                distributions.push({
                  legal_name: legalName,
                  distribution: value,
                })
              }
            }
          })

          payload.companies = distributions
          payload.other_balance_sheet_items = otherBalanceSheetItems
          break
      }

      // Send payload to server
      fetch(
        `/manage/snapshots/${this.element.dataset.snapshotId}/update_statements`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
              .content,
          },
          body: JSON.stringify(payload),
        },
      )
        .then((response) => {
          if (response.ok) {
            // Switch back to the tab we were editing
            this.activateTab(this.editingTabValue)
            this.updateTable()

            this.editModeValue = false
            this.editingTabValue = ""
            e.target.textContent = "Edit Data"
          } else {
            console.error("Failed to update statements")
          }
        })
        .catch((error) => {
          console.error("Error updating statements:", error)
        })
    } else {
      // Edit mode
      this.editModeValue = true
      this.editingTabValue = activeTabId
      e.target.textContent = "Save Changes"
    }
  }

  editModeValueChanged() {
    if (this.editModeValue) {
      // Only make cells editable in the current editing tab
      const editingPane = document.getElementById(this.editingTabValue)
      if (editingPane) {
        const editableCells = editingPane.querySelectorAll(
          '[data-snapshot-statements-review-target="editableCell"]',
        )
        editableCells.forEach((cell) => {
          const value = parseFloat(cell.textContent.replace(/[^0-9.-]+/g, ""))
          if (cell.dataset.type === "select") {
            const currentValue = cell.textContent.trim().toLowerCase()
            cell.innerHTML = `
              <select class="form-control">
                <option value="quarterly" ${
                  currentValue === "quarterly" ? "selected" : ""
                }>Quarterly</option>
                <option value="yearly" ${
                  currentValue === "yearly" ? "selected" : ""
                }>Yearly</option>
              </select>`
          } else {
            cell.innerHTML = `<div class="input-group-text">$</div><input type="number" step="0.01" class="form-control" value="${value}">`
          }
        })

        // Show remove buttons and add row
        this.removeActionTargets.forEach((action) =>
          action.classList.remove("d-none"),
        )
        this.addRowTrTarget.classList.remove("d-none")

        // Italicize the current tab
        const currentTab = document.querySelector(
          `[data-tab-id="${this.editingTabValue}"]`,
        )
        if (currentTab) {
          currentTab.style.fontStyle = "italic"
        }
      }
    } else {
      // Revert all editable cells back to text
      this.editableCellTargets.forEach((cell) => {
        const input = cell.querySelector("input")
        if (input) {
          const value = parseFloat(input.value)
          cell.textContent = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(value)
        }
      })

      // Hide remove buttons and add row
      this.removeActionTargets.forEach((action) =>
        action.classList.add("d-none"),
      )
      this.addRowTrTarget.classList.add("d-none")

      // Reset all tab styles
      document.querySelectorAll("[data-tab-id]").forEach((tab) => {
        tab.style.fontStyle = "normal"
      })
    }
  }

  removeRow(event) {
    event.preventDefault()
    const row = event.currentTarget.closest("tr")
    if (row) {
      row.classList.add("d-none")
    }
  }

  onAddRow(e) {
    e.preventDefault()
    $(this.addPortcoModalTarget).modal("show")
  }

  togglePortcoForm(e) {
    const isExisting = e.target.value === "existing"
    this.existingCompanyFormTarget.classList.toggle("d-none", !isExisting)
    this.newCompanyFormTarget.classList.toggle("d-none", isExisting)
  }

  onAddPortcoConfirm(e) {
    e.preventDefault()

    const isExisting = document.querySelector("#existingCompanyRadio").checked

    if (isExisting) {
      const portfolioCompany =
        this.portfolioCompanySelectTarget.selectedOptions[0]
      if (!portfolioCompany) {
        alert("Please select a portfolio company")
        return
      }

      $.ajax({
        url:
          window.location.pathname + "/add_existing_company_statement_review",
        method: "POST",
        data: {
          company_name: portfolioCompany.text,
          portfolio_company_id: portfolioCompany.value,
        },
        success: () => {
          $(this.addPortcoModalTarget).modal("hide")
          window.location.reload()
        },
        error: () => {
          alert("Error mapping portfolio company")
        },
      })
    } else {
      const legalName = this.newCompanyLegalNameTarget.value.trim()
      const displayName = this.newCompanyDisplayNameTarget.value.trim()

      if (!legalName) {
        alert("Please fill in Legal Name")
        return
      }

      $.ajax({
        url: window.location.pathname + "/create_company_statement_review",
        method: "POST",
        data: {
          company_name: legalName,
          legal_name: legalName,
          display_name: displayName,
        },
        success: () => {
          $(this.addPortcoModalTarget).modal("hide")
          window.location.reload()
        },
        error: () => {
          alert("Error creating portfolio company")
        },
      })
    }
  }
}
