import { Controller } from "stimulus"
import { Dropzone } from "dropzone"
import SparkMD5 from "spark-md5"
import qaLogger from "../util/qaLogger"

export default class extends Controller {
  static targets = [
    "assetSelect",
    "submit",
    "form",
    "right",
    "financials",
    "statements",
    "rows",
    "row",
    "financialsDownloadBtn",
    "statementsDownloadBtn",
    "tableContainer",
    "addPortcoModal",
    "portfolioCompanySelect",
    "addRowTr",
  ]

  initialize() {
    if (this.hasFormTarget) {
      $(this.formTarget).ajaxForm({
        beforeSubmit: () => {
          this.clearErrors(this.rowTargets)
          this.toggleFormState(this.rowTargets, false)
        },
        error: (response) => {
          try {
            const errorData = JSON.parse(response.responseText)

            this.clearErrors(this.rowTargets)

            // Add new errors
            if (errorData.details) {
              errorData.details.forEach((error) => {
                const row = this.rowTargets[error.row]
                if (row) {
                  const errorDiv = document.createElement("div")
                  errorDiv.className = "error-message"
                  errorDiv.textContent = error.message
                  row.appendChild(errorDiv)

                  // Add error styling to the row
                  row.classList.add("has-error")
                  row
                    .querySelectorAll(".dropzone")
                    .forEach((el) => el.classList.add("has-error"))
                  row.querySelector("select").classList.add("has-error")
                }
              })
            }
          } catch (e) {
            console.error("Error parsing error response:", e)
          }

          this.toggleFormState(this.rowTargets, true)
          this.updateSubmitState()
        },
      })
    }
  }
  static get DROPZONE_PREVIEW_TEMPLATE() {
    const closeCirclePath =
      document.getElementById("snapshot-assets")?.dataset.closeCirclePath ||
      "/assets/icons/close-circle.svg"
    return `
      <div class="dz-snapshot-preview">
        <span class="dz-filename"><span data-dz-name></span></span>
        <a class="dz-remove" href="javascript:undefined;" data-dz-remove>
          <img src="${closeCirclePath}" alt="Remove file" style="color: inherit;">
        </a>
      </div>
    `
  }
  static targets = [
    "assetSelect",
    "submit",
    "form",
    "right",
    "financials",
    "statements",
    "rows",
    "row",
    "financialsDownloadBtn",
    "statementsDownloadBtn",
  ]

  clearErrors(rows) {
    rows.forEach((row) => {
      row.querySelectorAll(".error-message").forEach((el) => el.remove())
      row
        .querySelectorAll(".has-error")
        .forEach((el) => el.classList.remove("has-error"))
      row.classList.remove("has-error")
    })
  }

  toggleFormState(rows, active) {
    rows.forEach((row) => {
      row
        .querySelectorAll("select, button")
        .forEach((el) => (el.disabled = !active))
    })
    this.$submit.prop("disabled", !active)
  }

  connect() {
    this.convertTargets()
    if (this.hasRowTarget) {
      this.setupDropzones(this.rowTargets[0])
    }
  }

  convertTargets() {
    // converts targets into this.${target}
    this.constructor.targets.forEach((target) => {
      if (
        this[
          "has" + target.charAt(0).toUpperCase() + target.slice(1) + "Target"
        ]
      ) {
        this[`$${target}`] = $(this[`${target}Target`])
      }
    })
  }

  // Browser based crypto APIs were causing issues, so this is a md5 hash
  // implementation using the SparkMD5 library.
  //
  // This isn't technically needed as we can set the digest to any value, and
  // UUID may be sufficient. However, given we can control the value their is
  // some marginal utility here as we can regenerate the hash from original
  // files and compare with the blob value to see if resources have ever changed
  async calculateMD5(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      const spark = new SparkMD5.ArrayBuffer()

      reader.onload = () => {
        spark.append(reader.result)
        const hash = spark.end()
        const hashBase64 = btoa(
          hash
            .match(/[\da-f]{2}/gi)
            .map((h) => String.fromCharCode(parseInt(h, 16)))
            .join(""),
        )
        resolve(hashBase64)
      }
      reader.onerror = () => reject(reader.error)
      reader.readAsArrayBuffer(file)
    })
  }

  async getDirectUploadUrl(file) {
    const md5Hash = await this.calculateMD5(file)
    const response = await fetch("/storage/direct_uploads", {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        blob: {
          filename: file.name,
          content_type: file.type,
          byte_size: file.size,
          checksum: md5Hash,
        },
      }),
    })

    if (!response.ok) {
      throw new Error(
        `Failed to get direct upload URL (Status: ${response.status})`,
      )
    }

    return response.json()
  }

  async uploadToS3(file, directUploadData) {
    try {
      const response = await fetch(directUploadData.direct_upload.url, {
        method: "PUT",
        headers: {
          ...directUploadData.direct_upload.headers,
        },
        body: file,
      })

      if (!response.ok) {
        const errorText = await response.text()
        qaLogger(
          `S3 Upload Failed:\n` +
            `Status: ${response.status}\n` +
            `Status Text: ${response.statusText}\n` +
            `Error Details: ${errorText}\n` +
            `Upload URL: ${directUploadData.direct_upload.url}\n` +
            `Upload URL Headers: ${JSON.stringify(
              directUploadData.direct_upload.headers,
              null,
              2,
            )}\n` +
            `File Name: ${file.name}\n` +
            `File Size: ${file.size} bytes\n` +
            `Content Type: ${file.type}\n`, //+
        )
        throw new Error(errorText)
      }

      return directUploadData.signed_id
    } catch (error) {
      console.error("S3 Upload Error:", error)
      throw error
    }
  }

  getBaseDropzoneConfig() {
    return {
      url: "/storage/direct_uploads",
      maxFiles: 1,
      maxFilesize: 32,
      acceptedFiles: ".pdf",
      addRemoveLinks: false,
      clickable: false,
      dictDefaultMessage: "Choose File",
      createImageThumbnails: false,
      previewTemplate: this.constructor.DROPZONE_PREVIEW_TEMPLATE,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      transformFile: (file, done) => done(file),
      sending: this.configureSending.bind(this),
      chunking: false,
      createImageThumbnails: false,
      accept: this.handleFileAccept.bind(this),
    }
  }

  configureSending(file, xhr, formData) {
    formData.set("blob[filename]", file.name)
    formData.set("blob[content_type]", file.type)
    formData.set("blob[byte_size]", file.size)
    formData.set("blob[checksum]", file.upload.uuid)
  }

  async handleFileAccept(file, done) {
    try {
      const directUploadData = await this.getDirectUploadUrl(file)
      const signedId = await this.uploadToS3(file, directUploadData)
      file.signed_id = signedId
      done()
    } catch (error) {
      console.error("Upload failed:", error)
      done(error)
    }
  }

  setupDropzones(row = null) {
    const container = row || this.element
    const baseConfig = this.getBaseDropzoneConfig()
    const isShowPage = this.isShowPage()

    const createDropzone = (targetName) => {
      const element = container.querySelector(
        `[data-snapshots-target="${targetName}"]`,
      )
      if (!element) return null

      const dropzoneConfig = {
        ...baseConfig,
        paramName: targetName,
        clickable: element,
      }

      if (isShowPage) {
        dropzoneConfig.autoProcessQueue = true
      }

      return new Dropzone(element, dropzoneConfig)
    }

    const financialsDropzone = createDropzone("financials")
    const statementsDropzone = createDropzone("statements")

    if (financialsDropzone) {
      this.setupDropzoneCallbacks(financialsDropzone, container)
    }
    if (statementsDropzone) {
      this.setupDropzoneCallbacks(statementsDropzone, container)
    }
  }

  setupDropzoneCallbacks(dropzone, container) {
    dropzone.on("addedfile", () => {
      if (this.isShowPage()) {
        // Hide the relevant download button based on dropzone type
        if (dropzone.options.paramName === "financials") {
          const downloadBtn = container.querySelector(
            '[data-snapshots-target="financialsDownloadBtn"]',
          )
          if (downloadBtn) {
            downloadBtn.style.display = "none"
          }
        } else if (dropzone.options.paramName === "statements") {
          const downloadBtn = container.querySelector(
            '[data-snapshots-target="statementsDownloadBtn"]',
          )
          if (downloadBtn) {
            downloadBtn.style.display = "none"
          }
        }
      }
    })

    dropzone.on("success", (file) => {
      if (this.isShowPage()) {
        this.createHiddenField(
          container,
          dropzone.options.paramName,
          null,
          file.signed_id,
        )
      } else {
        const rowIndex = Array.from(this.rowTargets).indexOf(
          container.closest('[data-snapshots-target="row"]'),
        )
        this.createHiddenField(
          container,
          dropzone.options.paramName,
          rowIndex,
          file.signed_id,
        )
      }
      this.updateSubmitState()
    })

    dropzone.on("removedfile", () => {
      if (this.isShowPage()) {
        // Show the relevant download button based on dropzone type
        if (dropzone.options.paramName === "financials") {
          const downloadBtn = container.querySelector(
            '[data-snapshots-target="financialsDownloadBtn"]',
          )
          if (downloadBtn) {
            downloadBtn.style.display = ""
          }
        } else if (dropzone.options.paramName === "statements") {
          const downloadBtn = container.querySelector(
            '[data-snapshots-target="statementsDownloadBtn"]',
          )
          if (downloadBtn) {
            downloadBtn.style.display = ""
          }
        }
      }
      this.removeHiddenField(dropzone.options.paramName)
      this.updateSubmitState()
    })
  }

  createHiddenField(container, paramName, rowIndex, value) {
    const hiddenField = document.createElement("input")
    hiddenField.setAttribute("type", "hidden")
    const fieldName = this.isShowPage()
      ? paramName
      : `snapshots[${rowIndex}][${paramName}]`
    hiddenField.setAttribute("name", fieldName)
    hiddenField.setAttribute("value", value)
    container.appendChild(hiddenField)
  }

  removeHiddenField(paramName) {
    const input = this.formTarget.querySelector(`input[name="${paramName}"]`)
    if (input) input.remove()
  }

  onAddRow(e) {
    e.preventDefault()
    $(this.addPortcoModalTarget).modal("show")
  }

  onAddPortcoConfirm(e) {
    e.preventDefault()

    const portfolioCompanyId = this.portfolioCompanySelectTarget.value
    if (!portfolioCompanyId) {
      return
    }

    // Add the new row to the table
    $.ajax({
      url: window.location.pathname + "/update_statements",
      method: "POST",
      data: {
        tab: "portfolio-companies",
        companies: {
          [portfolioCompanyId]: {
            cost: 0,
            fair_value: 0,
          },
        },
      },
      success: () => {
        $(this.addPortcoModalTarget).modal("hide")
        window.location.reload()
      },
      error: () => {
        alert("Error adding portfolio company")
      },
    })
  }

  updateSubmitState() {
    const rows = this.rowTargets
    let allRowsValid = true
    const isShowPage = this.isShowPage()

    rows.forEach((row) => {
      const select = row.querySelector("select")
      const hasAsset = select ? select.value : true
      const financialsDropzone = row.querySelector(
        '[data-snapshots-target="financials"]',
      )
      const statementsDropzone = row.querySelector(
        '[data-snapshots-target="statements"]',
      )

      const hasFinancials = financialsDropzone.classList.contains("dz-started")
      const hasStatements = statementsDropzone.classList.contains("dz-started")

      if (isShowPage) {
        allRowsValid = hasFinancials || hasStatements
      } else {
        if (!(hasAsset && hasFinancials && hasStatements)) {
          allRowsValid = false
        }
      }
    })

    this.$submit.prop("disabled", !allRowsValid)
  }

  onSelectAsset() {
    const assetId = this.assetSelectTarget.value
    if (assetId) {
      this.updateSubmitState()
    }
  }

  removeRow(event) {
    event.preventDefault()
    const row = event.target.closest('[data-snapshots-target="row"]')
    const isOnlyRow = this.rowTargets.length === 1

    if (isOnlyRow) {
      this.clearRow(row)
    } else {
      row.remove()
      this.updateRemainingRowIndexes()
    }

    this.updateSubmitState()
  }

  clearRow(row) {
    row.querySelector("select").value = ""
    this.clearDropzones(row)
    this.clearHiddenFields(row)
  }

  clearDropzones(row) {
    row.querySelectorAll(".dropzone").forEach((dropzoneElement) => {
      const dropzoneInstance = Dropzone.forElement(dropzoneElement)
      if (dropzoneInstance) {
        dropzoneInstance.removeAllFiles(true)
      }
      dropzoneElement.classList.remove("dz-started")
      const msgElement = dropzoneElement.querySelector(".dz-message")
      if (msgElement) {
        msgElement.style.display = "block"
      }
    })
  }

  clearHiddenFields(row) {
    row
      .querySelectorAll('input[type="hidden"]')
      .forEach((input) => input.remove())
  }

  updateRemainingRowIndexes() {
    this.rowTargets.forEach((row, index) => {
      this.updateElementIndex(row.querySelector("select"), index)

      row.querySelectorAll('input[type="file"]').forEach((input) => {
        this.updateElementIndex(input, index)
      })

      row.querySelectorAll('input[type="hidden"]').forEach((input) => {
        this.updateElementIndex(input, index)
      })
    })
  }

  updateElementIndex(element, index) {
    if (element) {
      element.name = element.name.replace(/\[\d+\]/, `[${index}]`)
    }
  }

  isShowPage() {
    return (
      window.location.pathname.includes("/manage/snapshots/") &&
      !window.location.pathname.endsWith("/snapshots")
    )
  }

  addRow(event) {
    event.preventDefault()
    const newRowIndex = this.rowTargets.length
    const template = this.rowTargets[0].cloneNode(true)

    // Remove error states
    template.classList.remove("has-error")
    template.querySelectorAll(".error-message").forEach((el) => el.remove())
    template
      .querySelectorAll(".has-error")
      .forEach((el) => el.classList.remove("has-error"))

    // Clear any existing values
    template.querySelectorAll("select").forEach((select) => {
      select.value = ""
      select.name = select.name.replace(/\[0\]/, `[${newRowIndex}]`)
    })
    template.querySelectorAll(".dropzone").forEach((dropzone) => {
      // Reset dropzone to initial state
      dropzone.innerHTML = `
        <input type="file" name="${dropzone
          .querySelector('input[type="file"]')
          .name.replace(
            /\[0\]/,
            `[${newRowIndex}]`,
          )}" direct-upload="true" style="display: none">
        <div class="dropzone-msg dz-message needsclick">
          <span class="dropzone-msg-desc">Choose File</span>
        </div>
      `
      dropzone.classList.remove("dz-started", "dz-clickable")
    })
    template
      .querySelectorAll('input[type="hidden"]')
      .forEach((input) => input.remove())

    this.rowsTarget.appendChild(template)
    this.setupDropzones(template)
    this.updateSubmitState()
  }
}
